import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CFormSelect,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory,Link } from "react-router-dom";

import "@coreui/coreui/dist/css/coreui.min.css";
import API from "../../api";
import {
  cilLockLocked,
  cilUser,
  cilLockUnlocked,
  cilCommentSquare,
} from "@coreui/icons";

const Register = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [recoveryQuestions, setRecoveryQuestions] = useState([]);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const initialValues = {
    username: "",
    password: "",
    confirmPassword: "",
    recovery_question: "",
    recovery_answer: "",
  };

  useEffect(() => {
    retrieveQuestions();
  }, []);

  const validationSchema = Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    recovery_question: Yup.string().required("Recovery question is required"),
    recovery_answer: Yup.string().required("Recovery answer is required"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    let dto = {
      ...values,
    };
    delete dto.confirmPassword; // we don't need this

    const response = await API.post("register", dto);
    if (
      response.status === "Bad Request" ||
      response.status === "Server Error"
    ) {
      if (response.errors) {
        response.errors.forEach((val) => {
          toast.error(val.msg, {
            position: "top-right",
          });
        });
      } else {
        toast.error(response.message, {
          position: "top-right",
        });
      }
    } else {
      if (response.role.toLowerCase() === "user") {
        localStorage.setItem("token", response.token);
        localStorage.setItem("username", dto.username);
        localStorage.setItem("user_id_nero", response.user_id);
        history.push("/profile");
      } else {
        toast.error("Access denied: Only Users can log in", {
          position: "top-right",
        });
      }
    }
  };

  const retrieveQuestions = async () => {
    try {
      const response = await API.get("questions");
      if (
        response.status === "Bad Request" ||
        response.status === "Server Error"
      ) {
        if (response.errors) {
          response.errors.forEach((val) => {
            toast.error(val.msg, {
              position: "top-right",
            });
          });
        } else {
          toast.error(response.message, {
            position: "top-right",
          });
        }
      } else {
        setRecoveryQuestions(response.data);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred", {
        position: "top-right",
      });
    }
  };

  return (
    <div className="bg-black bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center p-10">
          <CCol
            md={9}
            lg={7}
            xl={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CCard className="mx-4 bg-blue sm:p-7 max-w-[27rem] justify-center">
              <CCardBody className="p-7">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="d-flex justify-center">
                        <Link
                          to="/"
                          className="flex items-center justify-center flex-row text-white"
                        >
                          <div className="logo-1">
                            <span>URBAN</span>
                          </div>
                          <div className="logo-2">
                            <span>GAMES</span>
                          </div>
                        </Link>
                      </div>
                      <h1 className="text-3xl font-medium pb-2" style={{color:'white', marginTop:'40px', marginBottom:'24px'}}>Register</h1>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <Field
                          name="username"
                          as={CFormInput}
                          placeholder="Username"
                          autoComplete="username"
                        />
                      </CInputGroup>
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="text-danger mb-3"
                      />
                      {/* <CInputGroup className="mb-3">
                        <CInputGroupText>@</CInputGroupText>
                        <Field name="email" as={CFormInput} placeholder="Email" autoComplete="email" />
                      </CInputGroup>
                      <ErrorMessage name="email" component="div" className="text-danger mb-3" /> */}
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <Field
                          name="password"
                          as={CFormInput}
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          autoComplete="new-password"
                        />
                        <CInputGroupText
                          onClick={toggleShowPassword}
                          style={{ cursor: "pointer" }}
                        >
                          <CIcon
                            icon={
                              showPassword ? cilLockUnlocked : cilLockLocked
                            }
                          />
                        </CInputGroupText>
                      </CInputGroup>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger mb-3"
                      />
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <Field
                          name="confirmPassword"
                          as={CFormInput}
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Repeat password"
                          autoComplete="new-password"
                        />
                        <CInputGroupText
                          onClick={toggleShowConfirmPassword}
                          style={{ cursor: "pointer" }}
                        >
                          <CIcon
                            icon={
                              showConfirmPassword
                                ? cilLockUnlocked
                                : cilLockLocked
                            }
                          />
                        </CInputGroupText>
                      </CInputGroup>
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-danger mb-3"
                      />
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilCommentSquare} />
                        </CInputGroupText>
                        <Field
                          name="recovery_question"
                          as={CFormSelect}
                          autoComplete="recovery-question"
                        >
                          <option value="">
                            Select a recovery question...
                          </option>
                          {recoveryQuestions.map((question, index) => (
                            <option key={index} value={question.id}>
                              {question.text}
                            </option>
                          ))}
                        </Field>
                      </CInputGroup>
                      <ErrorMessage
                        name="recovery_question"
                        component="div"
                        className="text-danger mb-3"
                      />
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilCommentSquare} />
                        </CInputGroupText>
                        <Field
                          name="recovery_answer"
                          as={CFormInput}
                          placeholder="Recovery Answer"
                          autoComplete="recovery-answer"
                        />
                      </CInputGroup>
                      <ErrorMessage
                        name="recovery_answer"
                        component="div"
                        className="text-danger mb-3"
                      />
                      <div className="d-grid">
                        <CButton
                          color="success"
                          style={{
                            backgroundColor: "#003465",
                            borderColor: "transparent",
                          }}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Create Account
                        </CButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
      <ToastContainer />
    </div>
  );
};

export default Register;
