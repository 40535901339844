import React from "react";
import { Link } from "react-router-dom";

import Nero from "../../assets/img/logo/nero-logo.png";
function Footer() {
  return (
    <footer>
      <div className="footer-top footer-bg" style={{padding:'100px 0px'}}>
        {/* newsletter-area */}
        <div className="newsletter-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* <div className="newsletter-wrap">
                  <div className="section-title newsletter-title">
                      <h2>Our <span>Newsletter</span></h2>
                    </div>
                    <div className="newsletter-form">
                      <form>
                        <div className="newsletter-form-grp">
                          <i className="far fa-envelope" />
                          <input type="email" placeholder="Enter your email..." />
                        </div>
                        <button>SUBSCRIBE <i className="fas fa-paper-plane" /></button>
                      </form>
                    </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* newsletter-area-end */}
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="footer-widget mb-50">
                <div className="footer-logo mb-35">
                  <div className="logo">
                    <Link
                      to="/"
                      className="flex items-center justify-center flex-row text-white"
                    >
                      <div className="logo-1">
                        <span>URBAN</span>
                      </div>
                      <div className="logo-2">
                        <span>GAMES</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="footer-text">
                  {/* <p>
                    Nero Games platform online terkemuka untuk semua penggemar
                    game. Mainkan game terbaru dan terpopuler, top up akun Anda
                    dengan mudah.
                  </p> */}
                  {/* <div className="footer-contact">
                      <ul>
                        <li><i className="fas fa-map-marker-alt" /> <span>Address : </span>PO Box W75 Street
                          lan West new queens</li>
                        <li><i className="fas fa-headphones" /> <span>Phone : </span>+24 1245 654 235</li>
                        <li><i className="fas fa-envelope-open" /><span>Email : </span>info@exemple.com</li>
                      </ul>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-sm-6">
              <div className="footer-widget mb-50">
                {/* <div className="fw-title mb-35">
                  <h5>Products</h5>
                </div> */}
                <div className="fw-link">
                  <ul>
                    <li>
                      <a
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                          fontSize: "16px",
                          color: "white",
                        }}
                        href="/top-up"
                      >
                        Top Up
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="footer-widget mb-50">
                <div className="fw-title mb-35">
                  <h5>Upcoming Products</h5>
                </div>
                <div className="fw-link">
                  <ul>
                    <li>
                      <a
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                          fontSize: "16px",
                          color: "white",
                        }}
                        href="/shop"
                      >
                        Idle Games
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="col-xl-2 col-lg-3 col-sm-6">
              <div className="footer-widget mb-50">
                {/* <div className="fw-title mb-35">
                  <h5>Need Help?</h5>
                </div> */}
                <div className="fw-link">
                  <ul>
                    {/* <li>
                      <a
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                          fontSize: "16px",
                          color: "white",
                        }}
                        href="/terms-and-condition"
                      >
                        Terms &amp; Conditions
                      </a>
                    </li> */}
                    {/* <li><a  href="/privacy-policy">Privacy Policy</a></li>
                      <li><a  href="/refund-policy">Refund Policy</a></li> */}
                    {/* <li>
                      <a
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                          fontSize: "16px",
                          color: "white",
                        }}
                        href="/faq"
                      >
                        FAQ and Use Cases
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="footer-widget mb-50">
                {/* <div className="fw-title mb-35">
                  <h5>Follow us</h5>
                </div> */}
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Newsletter Sign Up</h5>
                  </div>
                  <div className="footer-newsletter">
                    <form >
                      <input type="text" placeholder="Enter your email" />
                      <button><i className="fas fa-rocket" /></button>
                    </form>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
        {/* <div className="footer-fire">
          <img src="assets/img/images/footer_fire.png" alt="" />
        </div>
        <div className="footer-fire footer-fire-right">
          <img src="assets/img/images/footer_fire.png" alt="" />
        </div> */}
      </div>
      {/* <div className="copyright-wrap">
        <div className="container footer-m">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="copyright-text">
                <p>
                  Copyright © 2024 <a href="/#">Nero Games</a> All Rights
                  Reserved.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-none d-md-block"></div>
          </div>
        </div>
      </div> */}
    </footer>
  );
}

export default Footer;
