import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import API from "../../api";
import {
  CCard,
  CCardBody,
  CContainer,
  CSpinner,
  CAlert,
  CButton,
} from "@coreui/react";
import NeroLoader from "../../components/loaders/NeroLoader";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await API.post("get-user");
        if (response) {
          setUserData(response?.user);
        } else {
          setError(response?.data?.message || "Failed to fetch user data");
        }
      } catch (error) {
        setError("An error occurred while fetching user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const navigateToHistory = () => {
    if (userData) {
      history.push(`/transaction-history/${userData.id}`);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen w-full top-up-page shop-area">
        <NeroLoader />
      </div>
    );
  }

  if (error) {
    return (
      <CContainer className="min-vh-100 d-flex justify-content-center align-items-center bg-all-container">
        <CAlert color="danger">{error}</CAlert>
      </CContainer>
    );
  }

  return (
    <div className="bg-black bg-body-tertiary min-vh-100 d-flex justify-content-center align-items-center">
      <CCard className="profile-card">
        <CCardBody>
          <div className="profile-header">
            <Link
              to="/"
              className="flex items-center justify-center flex-row text-white"
            >
              <div className="logo-1">
                <span>URBAN</span>
              </div>
              <div className="logo-2">
                <span>GAMES</span>
              </div>
            </Link>
            <h2
              style={{
                marginTop: 40,
                marginBottom: 24,
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              Hello [{userData.username}]
            </h2>
          </div>
          <div className="profile-header">
                <h2>Urban Coin: <span style={{color:'orange', fontWeight:'bold'}}>{userData.amount}</span></h2>
                <CButton className="game-button" href="/top-up-game-coin">Select Game</CButton>
              </div>
          <div className="profile-details">
            <div className="detail-header">
              <div className="detail">
                <strong>Username</strong>
                <strong>User ID</strong>
                {userData.nik && <strong>Ref ID</strong>}
              </div>
            </div>
            <div className="detail-body">
              <div className="detail">
                <strong style={{ fontWeight: "lighter" }}>
                  {userData.username}
                </strong>
                <strong style={{ fontWeight: "lighter" }}>{userData.id}</strong>
                {userData.nik && (
                  <strong style={{ color: "yellow" }}>
                    {userData.ref_id ? userData.ref_id : "Ref ID is not set"}
                  </strong>
                )}
              </div>
            </div>
          </div>
          {userData.role.toLowerCase() === "agent" && (
            <div className="profile-notif">
              <p style={{ color: "red" }}>
                *Please ask support for set up the referral ID and credentials
                for the dashboard page
              </p>
            </div>
          )}
          <div className="profile-actions">
            {userData.role.toLowerCase() === "agent" ? (
              <>
                <CButton
                  color="primary"
                  className="profile-button"
                  onClick={() =>
                    window.open("https://dashboard.nerogames.id/", "_blank")
                  }
                >
                  Dashboard
                </CButton>
                <CButton
                  color="primary"
                  className="profile-button"
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send?phone=6281367802464",
                      "_blank"
                    )
                  }
                >
                  Contact Support
                </CButton>
              </>
            ) : (
              <>
                <CButton
                  color="primary"
                  className="profile-button"
                  onClick={navigateToHistory}
                >
                  History Transaksi
                </CButton>
                <CButton
                  color="primary"
                  className="profile-button"
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send?phone=6281367802464",
                      "_blank"
                    )
                  }
                >
                  Kontak Support
                </CButton>
                <CButton
                    color="primary"
                    className="profile-button full-width-button"
                    href="/top-up-coin"
                    
                  >
                    Top Up Urban Coin
                  </CButton>
              </>
            )}
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default Profile;
