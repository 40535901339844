import React, { lazy, Suspense } from 'react';
// import Footer from '../../footer/Footer';
import HeaderTwo from '../../header/HeaderTwo';
import SliderTwo from './SliderTwo';
import GamesAreatwo from './GamesAreatwo'
import ShopAreaTwo from './ShopAreaTwo'
import BlogArea from './BlogArea'
import HeroSection from '../../sections/homepages/HeroSection';

const Footer = lazy(() => import('../../footer/Footer'));
const CtaArea = lazy(() => import('./CtaArea'));
const NewGamesArea = lazy(() => import('./NewGamesArea'));
const GamesArea = lazy(() => import('./GamesArea'));

function IndexTwo(){
	return(
        <>
         {/* header-area */}
          <HeaderTwo/>
        {/* main-area */}
        <main>
          {/* slider-area */}
          {/* <SliderTwo/> */}
          <HeroSection />
          
          {/* slider-area-end */}
          {/* new-games-area */}
          {/* <Suspense fallback={<div>Rendering...</div>}>
            <GamesArea/>
          </Suspense>

          <Suspense fallback={<div>Rendering...</div>}>
            <NewGamesArea/>
          </Suspense> */}
          {/* new-games-area-end */}
          {/* gamers-area */}
          {/* <GamesAreatwo/> */}
          {/* gamers-area-end */}
          {/* featured-game-area */}
          {/* featured-game-area-end */}
          {/* cta-area */}
          {/* <Suspense fallback={<div>Rendering...</div>}>
            <CtaArea/>
          </Suspense> */}
          {/* cta-area-end */}
          {/* shop-area */}
         {/* <ShopAreaTwo/> */}
          {/* shop-area-end */}
          {/* blog-area */}
          {/* <BlogArea/> */}
          {/* blog-area-end */}
        </main>
        {/* main-area-end */}
        <Suspense fallback={<div>Rendering...</div>}>
          <Footer/>
        </Suspense>
        </>
	)
}
	
export default IndexTwo