import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import "../../assets/css/style.css";
import API from "../../api";
import SUGAR from "../../assets/img/Home/SUGAR.png";
import IDLE from "../../assets/img/Home/IDLE.png";
import { CSpinner, CAlert } from "@coreui/react";
import { FormControl, Button } from "react-bootstrap"; // Import Bootstrap components
import NeroLoader from "../../components/loaders/NeroLoader";

const neroGames = [
  { id: "sugarpuffs", name: "SUGAR", image: SUGAR },
  { id: "idlemarkets", name: "IDLE", image: IDLE },
  { id: "jetpack", name: "JET PACK", image: "https://storage.googleapis.com/nero-assets/nero-game-logo/JET.webp" },
  { id: "bitcoinclicker", name: "BITCOIN CLICKER", image: "https://storage.googleapis.com/nero-assets/nero-game-logo/BILLIONAIRE.webp" },
];

const TopupSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  userID: Yup.string().required("User ID is required"),
  amount: Yup.number()
    .required("Amount is required")
    .min(10000, "Amount must be at least 10,000"),
  game: Yup.string().required("Please select a game"),
  ref_id: Yup.string().optional(),
});

const TopUpPage = () => {
  const history = useHistory();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // State for filtered products
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [initialValues, setInitialValues] = useState({
    username: "",
    phoneNumber: "",
    userID: "",
    amount: "",
    game: "",
    ref_id: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get("retrieveDTU");
        if (response.data.response.status === "200") {
          const productsList = response.data.response.list_dtu;
          setProducts(productsList);
          setFilteredProducts(productsList); // Initialize filtered products with full list
        } else {
          setError(response.data.message || "Failed to fetch products");
        }
      } catch (error) {
        setError("An error occurred while fetching products");
      } finally {
        setLoading(false);
      }
    };

    const fetchUserData = async () => {
      try {
        const response = await API.post("get-user");
        if (response?.user) {
          const loggedInUsername = localStorage.getItem("username");
          // const user = response.data.users.find(
          //   (user) => user.username === loggedInUsername
          // );
          if (response?.user) {
            setInitialValues((prevValues) => ({
              ...prevValues,
              username: response?.user.username,
              userID: response?.user.id,
            }));
          } else {
            setError("User not found");
          }
        } else {
          setError(response.data.message || "Failed to fetch user data");
        }
      } catch (error) {
        setError("An error occurred while fetching user data");
      }
    };

    fetchData();
    fetchUserData();
  }, []);

  useEffect(() => {
    // Filter products based on the search query
    if (searchQuery === "") {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter((product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery, products]);

  const handleGameClick = (gameName) => {
    history.push(`/product/${gameName}`);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  if (loading) {
    return (
      <div className="min-h-screen w-full top-up-page  bg-black bg-body-tertiary">
        <NeroLoader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-vh-100 d-flex justify-content-center align-items-center">
        <CAlert color="danger">{error}</CAlert>
      </div>
    );
  }

  return (
    <div className="top-up-page bg-black bg-body-tertiary">
      <div className="top-up-wrapper" style={{display:'flex',flexDirection:'column', justifyContent:'center' , alignItems:'center'}}>
        <h1 style={{fontSize:'28px', fontWeight:'bold'}}>Top Up Game Mudah dan Cepat</h1>
        <p style={{textAlign:'center' ,color:'white', fontWeight:'lighter'}}>Nikmati transaksi yang aman, cepat, dan terjangkau dengan proses pembayaran yang mudah. Cukup pilih game, masukkan ID pemain, dan lakukan pembayaran. Diamond dan item game favorit Anda akan langsung masuk ke akun Anda!</p>
      </div>
      
      <div className="search-container top-up-wrapper" style={{backgroundColor:'rgba(63, 63, 63, 0.5)', borderRadius:'12px' ,marginBottom: "20px",marginTop:'20px' }}>
        <FormControl
          type="search"
          placeholder="Cari Permainan..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className="top-up-wrapper" style={{ backgroundColor:'rgba(63, 63, 63, 0.5)', borderRadius:'12px', marginTop:'20px'}}>
        <h4 style={{ color: "white", fontWeight: "bold", fontSize: "32px",padding:10 }}>Top Up Voucher</h4>
        <div className="Game">
          <ul>
            {filteredProducts.map((game) => (
              <li
                key={game.id}
                className="game-card-topup"
                style={{backgroundImage: 'none'}}
                onClick={() => handleGameClick(game.name)}
              >
                <label style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                  <img
                    src={game.image}
                    alt={game.name}
                    style={{ cursor: "pointer", borderRadius:'10px' }}
                  />
                  <span style={{color:'white'}}>{game.name}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopUpPage;
