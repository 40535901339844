import React from "react";
import Fade from "react-reveal/Fade";

export default function HeroSection() {
  return (
    <section
      className="relative w-full h-full lg:min-h-[820px] min-h-[650px] bg-no-repeat flex flex-column items-center lg:justify-center justify-end antialiased overflow-hidden"
      style={{ backgroundColor: "black" }}
    >
      {/* Ellipse background image positioned on top */}

      <div
        className="flex items-center justify-start lg:pl-8 lg:pr-0 px-4 h-[100vh !important] w-full relative z-10"
        style={{
          backgroundImage: `url(https://storage.googleapis.com/urban-g-assets/home/urban-games-background.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >
        <img
          src="https://storage.googleapis.com/urban-g-assets/home/background-ellips-min.png"
          alt="ellipse background"
          className="absolute  w-full h-full z-0"
          style={{
            width: "100%",
            height: "auto",
            top: "350px", // Adjust this value for horizontal positioning
            right: "100px",
            objectFit: "fill",
            pointerEvents: "none",
            scale: "2",
          }}
        />
        <div style={{height:'100%'}} className="text-white leading-8 flex justify-center flex-col gap-10">
          <div className="text-center base:text-left flex flex-col items-start justify-start gap-2 lg:gap-0 max-w-[30rem] lg:max-w-full">
            <Fade up delay={0.5}>
              <h1
                className="font-extrabold text-[#ffff] text-4xl lg:text-[68px] xl:text-[80px] leading-1 lg:leading-[4.7rem] tracking-tighter uppercase"
                style={{ color: "white", maxWidth: "800px" }}
              >
                TOP UP MOBILE GAME MURAH &amp; CEPAT
              </h1>
            </Fade>
          </div>
        </div>
      </div>

      <div style={{ height: "auto", padding: "60px 0px", zIndex: 20 }}>
        <div className="section-1-title">
          <h4 style={{ color: "yellow", letterSpacing: "20px" }}>
            MOBILE GAMES
          </h4>
          <h2 style={{ marginTop: "20px", fontSize: 40, fontWeight: "bold" }}>
            PILIH GAME FAVORITMU
          </h2>
        </div>
        {/* Game Grid */}
        <div className="section-1-grid">
          {/* Add your game cards here */}
          <div className="game-card">
            <img src="https://semutganteng.fra1.digitaloceanspaces.com/UniPlay/ad1427f3fe23204d0c1c90c7087e52fc.png" />
            <div className="game-card-title blue-card" >
              <h4>Mobile Legends</h4>
            </div>
          </div>
          <div className="game-card">
            <img src="https://semutganteng.fra1.digitaloceanspaces.com/UniPlay/f6438e1b33a2af20aa6821c12b840548.jpeg" />
            <div className="game-card-title orange-card">
              <h4>PUBG</h4>
            </div>
          </div>
          <div className="game-card">
            <img src="https://semutganteng.fra1.digitaloceanspaces.com/UniPlay/f905467c126c2b272aee268a735a5d14.jpeg" />
            <div className="game-card-title purple-card">
              <h4>Genshin Impact</h4>
            </div>
          </div>
          <div className="game-card">
            <img src="https://uniplay.id/cdn/b2ddd9fed988ada8888f11917574b42b-large.jpeg" />
            <div className="game-card-title light-blue-card">
              <h4>Call of Duty Mobile</h4>
            </div>
          </div>
          <div className="game-card">
            <img src="https://semutganteng.fra1.digitaloceanspaces.com/UniPlay/54a9960aadb5de1938dec57081bdb642.png" />
            <div className="game-card-title green-card">
              <h4>Free Fire</h4>
            </div>
          </div>
          <div className="game-card">
            <img src="https://semutganteng.fra1.digitaloceanspaces.com/UniPlay/0e0502c3cc466b50f70897275b107376.jpeg" />
            <div className="game-card-title red-card">
              <h4>Honor of Kings</h4>
            </div>
          </div>
        </div>
      </div>

      <div style={{height: 'auto', display:'flex', flexDirection:'column'}}>
        <div className="hero-section-3">
          <h3 style={{fontSize: 26, fontWeight:'bold'}}>Lengkapi Koleksi Skin Mobile Legends</h3>
          <span style={{marginTop:20, maxWidth:600}}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
          </span>
          <img style={{padding:'50px 0px'}} src="https://storage.googleapis.com/urban-g-assets/home/skins-mlbb-thumbnail.webp"/>
        </div>
      </div>
    </section>
  );
}
