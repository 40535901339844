import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import ScrollToTop from "react-scroll-to-top";
import Nero from "../../assets/img/logo/nero-logo.png";
import { isAuthenticated, logout } from "../../utils/auth";

export default function HeaderTwo() {
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const [username, setUsername] = useState("");
  const [showSubMenu, setShowSubMenu] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      setUsername(localStorage.getItem("username"));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    $("body").removeClass("mobile-menu-visible");

    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .push-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );

      $(".menu-backdrop, .mobile-menu .close-btn").click(() => {
        $("body").removeClass("mobile-menu-visible");
      });

      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });
    }
  }, []);

  useEffect(() => {
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
      }
    });
  }, []);

  const handleLogout = () => {
    logout();
    setIsLoggedIn(false);
    history.push("/login");
  };

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  return (
    <header className="">
      <ScrollToTop smooth top="500" color="#000" />
      <div id="sticky-header" className="transparent-header menu-area z-50">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler two">
                <i className="fas fa-bars" />
              </div>
              <div className="main-menu menu-style-two">
                <nav>
                  <Link
                    to="/"
                    className="flex items-center justify-center flex-row text-white"
                  >
                    <div className="logo-1">
                      <span>URBAN</span>
                    </div>
                    <div className="logo-2">
                      <span>GAMES</span>
                    </div>
                  </Link>
                  <div
                    className="navbar-wrap push-menu d-none d-lg-flex navbar-100 justify-center"
                    style={{ marginRight: 100 }}
                  >
                    <ul className="navigation">
                      <li className="menu-item-has-children">
                        <Link style={{ textDecoration: "none" }} to="/">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link style={{ textDecoration: "none" }} to="/top-up">
                          Top Up
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{ textDecoration: "none" }}
                          to="/about-nero"
                        >
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link style={{ textDecoration: "none" }} to="/shop">
                          Game
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="navbar-wrap push-menu d-none d-lg-flex">
                    <ul className="navigation">
                      {!isLoggedIn ? (
                        <li className="menu-item-has-children">
                          <Link style={{ textDecoration: "none" }} to="/login">
                            Login
                          </Link>
                          <ul className="submenu">
                            <li>
                              <Link
                                style={{ textDecoration: "none" }}
                                to="/login"
                              >
                                User
                              </Link>
                            </li>
                          </ul>
                        </li>
                      ) : (
                        <li className="menu-item-has-children z-50">
                          <Link
                            to="#"
                            style={{ textDecoration: "none" }}
                            onClick={toggleSubMenu}
                          >
                            {username}
                          </Link>
                          {showSubMenu && (
                            <ul className="submenu">
                              <li>
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to="/profile"
                                >
                                  Profile
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/"
                                  style={{ textDecoration: "none" }}
                                  onClick={handleLogout}
                                >
                                  Logout
                                </Link>
                              </li>
                            </ul>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo">
                    <Link
                      to="/"
                      className="flex items-center justify-center flex-row text-white"
                    >
                      <div className="logo-1">
                        <span>URBAN</span>
                      </div>
                      <div className="logo-2">
                        <span>GAMES</span>
                      </div>
                    </Link>
                  </div>
                  <ul className="navigation">
                    <li>
                      <Link style={{ textDecoration: "none" }} to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link style={{ textDecoration: "none" }} to="/top-up">
                        Top Up
                      </Link>
                    </li>
                    <li>
                      <Link style={{ textDecoration: "none" }} to="/shop">
                        Game
                      </Link>
                    </li>
                    <li>
                      <Link style={{ textDecoration: "none" }} to="/about-nero">
                        About Us
                      </Link>
                    </li>
                    {!isLoggedIn ? (
                      <li className="menu-item-has-children">
                        <Link style={{ textDecoration: "none" }} to="/login">
                          Login
                        </Link>
                        <ul className="submenu">
                          <li>
                            <Link
                              style={{ textDecoration: "none" }}
                              to="/login"
                            >
                              User
                            </Link>
                          </li>
                          <li>
                            <Link
                              style={{ textDecoration: "none" }}
                              to="/reseller-login"
                            >
                              Reseller
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ) : (
                      <li className="menu-item-has-children">
                        <Link
                          to="#"
                          style={{ textDecoration: "none" }}
                          onClick={toggleSubMenu}
                        >
                          {username}
                        </Link>

                        <ul className="submenu">
                          <li>
                            <Link
                              style={{ textDecoration: "none" }}
                              to="/profile"
                            >
                              Profile
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/"
                              style={{ textDecoration: "none" }}
                              onClick={handleLogout}
                            >
                              Logout
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
              <div className="menu-backdrop" />
            </div>
            <div
              className="modal fade"
              id="search-modal"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form>
                    <input type="text" placeholder="Search here..." />
                    <button>
                      <i className="fa fa-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
