import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import gameData from '../../data/GameData' // Adjust the path according to your structure
import API from '../../api'

const GameDetail = () => {
  const { id } = useParams()
  const game = gameData.find((game) => game.id === id)

  console.log(game);
  

  const jwtToken = localStorage.getItem('token') // Replace with your actual JWT token

  useEffect(() => {
    const handleIframeLoad = () => {
      const iframe = document.getElementById('gameIframe')
      if (iframe && iframe.contentWindow) {
        const payload = {
          token: jwtToken,
          url: API.BASE_URL,
        }

        console.log('Sending payload to iframe', JSON.stringify(payload))
        setTimeout(()=>{
          iframe?.contentWindow?.postMessage(
            { payload: JSON.stringify(payload) },
            '*'
          )
        },5000)
      }
    }

    handleIframeLoad()
    const iframe = document.getElementById('gameIframe')
    if (iframe) {
      iframe.onload = handleIframeLoad
    }

    // Cleanup function to remove the onLoad handler when the component unmounts
    return () => {
      if (iframe) {
        iframe.onload = null
      }
    }
  }, [jwtToken, id])

  if (!game) {
    return <h2>Game not found</h2>
  }

  return (
    <div className='bg-black bg-body-tertiary' style={{display:"flex",flexDirection:'column', justifyContent:"center", alignItems:"center"}}>
      <div className={`${ game.id === "jetpacks" ? "pt-20": null } overflow-hidden flex flex-col items-center justify-center w-full`}>
        {game.url && (
          <iframe
            id="gameIframe"
            src={game.url}
            title={game.name}
            width={game.id !== "bitcoinclickers" ? game.width : "430px"}
            height={game.height}
            style={{ border: 'none', transform: game.transform }}
            // className={game.id === "bitcoinclickers" ? "w-max-[540px]" : "w-full"}
          ></iframe>
        )}
      </div>
    </div>
  )
}

export default GameDetail
