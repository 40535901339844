import React from "react";
import {Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../assets/css/style.css";
import BCA from "../../assets/img/store/bca.png";
import BNI from "../../assets/img/store/bni.png";
import BRI from "../../assets/img/store/bri.png";
import CIMB from "../../assets/img/store/cimbniaga.png";
import DANA from "../../assets/img/store/dana.png";
import DANAMON from "../../assets/img/store/danamon.png";
import GOPAY from "../../assets/img/store/gopay.png";
import LINKAJA from "../../assets/img/store/linkaja.png";
import MANDIRI from "../../assets/img/store/mandiri.png";
import PERMATA from "../../assets/img/store/permata.png";
import SHOPEE from "../../assets/img/store/shopeepay.png";
import QRIS from "../../assets/img/store/qris.png";
import SUGAR from "../../assets/img/Home/SUGAR.png";
import IDLE from "../../assets/img/Home/IDLE.png";


const games = [
  { id: "sugarpuffs", name: "SUGAR", image: SUGAR },
  { id: "idlemarkets", name: "IDLE", image: IDLE },
  {
    id: "jetpacks",
    name: "JET PACK",
    image: "https://storage.googleapis.com/nero-assets/nero-game-logo/JET.webp",
  },
  {
    id: "bitcoinclickers",
    name: "BITCOIN CLICKER",
    image:
      "https://storage.googleapis.com/nero-assets/nero-game-logo/BILLIONAIRE.webp",
  },
  {
    id: "warriorvsevil",
    name: "Warrior vs Evil",
    image: "https://storage.googleapis.com/nero-assets/home/man-vs-zombie-app.png",
  },
  {
    id: "ninjafruit",
    name: "Hayabusa Slash",
    image:
      "https://storage.googleapis.com/nero-assets/home/hayabusa-slash-app.png",
  },
];

function ShopArea() {
  return (
    <section className="bg-black bg-body-tertiary pt-120 pb-90">
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', textAlign:'center', padding:'100px 200px'}}>
      <h1 style={{fontSize:'36px', fontWeight:'bold'}}>Urban Games</h1>
      <p style={{marginTop:'20px'}}>Selamat datang di Urban Game Page! Nikmati game-game kami yang dapat dimainkan langsung di browser Anda tanpa perlu unduhan. Temukan keseruan bermain game online dengan berbagai pilihan permainan seru hanya di Urban Game Page!</p>
      </div>
      <div className="Game-show">
        {games.map((game) => (
          <div key={game.id}>
            <Link to={`/shop/${game.id}`}>
              <img
                style={{ borderRadius: 12 }}
                src={game.image}
                alt={game.name}
              />
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}

export default ShopArea;
