import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser, cilLockUnlocked } from "@coreui/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import API from "../../api";

const Login = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;

    const loginData = {
      username,
      password,
    };

    const response = await API.post("login", loginData);
    if (
      response.status === "Bad Request" ||
      response.status === "Server Error"
    ) {
      if (response.errors) {
        response.errors.forEach((val) => {
          toast.error(val.msg, {
            position: "top-right",
          });
        });
      } else {
        toast.error(response.message, {
          position: "top-right",
        });
      }
    } else {
      if (response.role.toLowerCase() === "user") {
        localStorage.setItem("token", response.token);
        localStorage.setItem("username", username); // Store username
        localStorage.setItem("user_id_nero", response.user_id); // Store user_id_nero
        history.push("/profile");
      } else {
        toast.error("Access denied: Only Users can log in", {
          position: "top-right",
        });
      }
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="bg-black bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center mobile-login">
          <CCol md={8}>
            <CCardGroup className="justify-center">
              <CCard className="bg-blue p-2 sm:p-7 max-w-[23rem]" style={{borderRadius:'28px', borderColor:'#5882C1'}}>
                <CCardBody>
                  <CForm onSubmit={handleLogin}>
                    <div className="d-flex justify-center">
                      <Link
                        to="/"
                        className="flex items-center justify-center flex-row text-white"
                      >
                        <div className="logo-1">
                          <span>URBAN</span>
                        </div>
                        <div className="logo-2">
                          <span>GAMES</span>
                        </div>
                      </Link>
                    </div>
                    <h1 className="text-3xl font-medium" style={{color:'white', padding:'30px 0px'}}>Login</h1>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        name="username"
                        placeholder="Username"
                        autoComplete="username"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4" style={{marginBottom:'5px !important'}}>
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                      <CInputGroupText
                        onClick={toggleShowPassword}
                        style={{ cursor: "pointer" }}
                      >
                        <CIcon
                          icon={showPassword ? cilLockUnlocked : cilLockLocked}
                        />
                      </CInputGroupText>
                    </CInputGroup>
                    <Link to="/forgot-password">
                          <CButton
                            color="link"
                            className="px-0 sm:text-base"
                            style={{padding:0, textTransform:'capitalize', fontSize:'8px', color:'white'}}
                          >
                            Lupa Password?
                          </CButton>
                        </Link>
                    <CRow className="mt-10">
                      <CCol className="d-flex justify-center" style={{padding:'0px 10px'}}>
                        <CButton color="primary" className="px-4" type="submit" style={{width:'100%', backgroundColor:'#003465'}}>
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                    <CRow >
                      <CCol className="d-flex justify-center">
                        <p style={{fontSize:'12px', color:'white', fontWeight:'lighter'}}>Belum punya akun Urban Games? <a href="/register" style={{fontWeight:'bold'}}>Register</a> disini</p>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
      <ToastContainer />
    </div>
  );
};

export default Login;
