import React from "react";
import banner1 from "../../assets/img/about/banner1.png";
import { Button } from "react-bootstrap";
function Aboutus() {
  return (
    <div className="bg-all-container bg-black">
      <div className="flex flex-col items-center justify-center mb-20 mt-32 gap-2">
        {/* <div className="Content-Wrapper flex">
          <img className="banner-img" src={banner1} alt="" />
          <div className="Text-wrapper">
            <h2>Tentang Nero Games</h2>
            <p style={{color:'white'}}>
            Selamat datang di Nero Games ID, tujuan utama Anda untuk game online berbasis web dan layanan top-up game mobile. Kami bersemangat tentang dunia game dan berdedikasi untuk memberikan pengalaman terbaik bagi para gamer.
            </p>
          </div>
        </div>

        <div className="Content-Wrapper banner2">
          <h2>Game Online Berbasis Web</h2>
          <p style={{color:'white'}}>
          Di Nero Games ID, kami menawarkan beragam pilihan game online berbasis web yang cocok untuk semua jenis pemain. Apakah Anda menyukai strategi, aksi, atau game kasual, platform kami menyediakan sesuatu untuk semua orang. Nikmati permainan tanpa hambatan langsung dari browser Anda tanpa perlu mengunduh atau menginstal apapun.
          </p>
        </div> */}

        <div className="Row">
          <div style={{display:'flex', justifyContent:'space-between', flexDirection:'column'}} className="Content-Wrapper w-100">
            <h2>
              <span style={{ color: "white", fontWeight:'bold', fontSize:'28px'}}>Tentang URBAN Games</span>
            </h2>
            <p style={{ color: "white", marginTop:'20px' }}>
              Urban Games adalah platform top up game terpercaya yang
              menyediakan layanan isi ulang untuk berbagai game mobile populer,
              seperti Mobile Legends, Honor of Kings, dan banyak game lainnya di
              Play Store. Kami berkomitmen untuk memberikan pengalaman top up
              yang aman, cepat, dan terjangkau bagi semua gamer. Dengan proses
              pembayaran yang aman dan terjamin, Urban Games memastikan
              transaksi Anda selesai dalam waktu singkat dengan harga terbaik.
              Kami hadir untuk mendukung kebutuhan gaming Anda dengan layanan
              yang murah, praktis, dan efisien. Bergabunglah dengan ribuan gamer
              lain yang telah mempercayai Urban Games untuk semua kebutuhan top
              up game mereka!
            </p>
            <a href="/top-up" style={{ backgroundColor:'orange', borderRadius:'0px', padding:'10px 20px',maxWidth:'100px', textAlign:'center'}}>Top Up</a>
          </div>
          <div className="Content-Wrapper w-100 m-0-10">
            <img src="https://storage.googleapis.com/urban-g-assets/home/mlbb-kof.png"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
