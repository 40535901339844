import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import API from "../../api";
import { useHistory } from "react-router-dom";
import { FormControl, Button } from "react-bootstrap";
const TopupSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  userID: Yup.string().required("User ID is required"),
  amount: Yup.number()
    .required("Amount is required")
    .min(5000, "Amount must be at least 5,000"),
  ref_id: Yup.string().optional(),
});

function TopUpWallet() {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [initialValues, setInitialValues] = useState({
    username: "",
    phoneNumber: "",
    userID: "",
    amount: "",
    ref_id: "",
  });
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await API.post("get-user");
        if (response?.user) {
          const loggedInUsername = localStorage.getItem("username");
          // const user = response.data.users.find(
          //   (user) => user.username === loggedInUsername
          // );
          if (response?.user) {
            setInitialValues((prevValues) => ({
              ...prevValues,
              username: response?.user.username,
              userID: response?.user.id,
            }));

          } else {
            setError("User not found");
          }
        } else {
          setError(response.data.message || "Failed to fetch user data");
        }
      } catch (error) {
        setError("An error occurred while fetching user data");
      }
    };

    fetchUserData();
  }, []);
  return (
    <div className="min-vh-100 bg-black bg-body-tertiary d-flex justify-content-center align-items-center bg-all-container wallet-padding" style={{backgroundPosition:"top"}}>
      
        <div
          className="top-up-wrapper padding"
          style={{ backgroundColor: "#2f002cd6", borderRadius: "12px" }}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={TopupSchema}
            onSubmit={(values) => {
              console.log('Form submitted:', values);
              history.push({
                pathname: `/payment/${values.userID}`,
                state: values,
              });
            }}
          >
            {({ errors, touched }) => (
              <Form className="wallet-layout">
                <h4
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "32px",
                    padding: 10,
                    alignSelf:'center'
                  }}
                >
                  Top Up Urban Coin
                </h4>
                <div className="form-container form-wallet">
                  <div className="left">
                    <div className="wrapper">
                      <h4
                        className="text-nowrap"
                        style={{ color: "black", fontSize: 14 }}
                      >
                        Username
                      </h4>
                      <div className="margin-40">
                        <Field
                          name="username"
                          className="form-control"
                          placeholder="Username"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="text-danger danger absolute"
                        />
                      </div>
                    </div>
                    <div className="wrapper">
                      <h4
                        className="text-nowrap"
                        style={{ color: "black", fontSize: 14 }}
                      >
                        Phone Number
                      </h4>
                      <div className="margin-20">
                        <Field
                          name="phoneNumber"
                          className="form-control"
                          placeholder="Phone Number"
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-danger danger absolute"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="wrapper">
                      <h4
                        className="text-nowrap"
                        style={{ color: "black", fontSize: 14 }}
                      >
                        User ID
                      </h4>
                      <div className="margin-40">
                        <Field
                          name="userID"
                          className="form-control"
                          placeholder="User ID"
                        />
                        <ErrorMessage
                          name="userID"
                          component="div"
                          className="text-danger danger absolute"
                        />
                      </div>
                    </div>
                    <div className="wrapper">
                      <h4
                        className="text-nowrap"
                        style={{ color: "black", fontSize: 14 }}
                      >
                        Input Amount
                      </h4>
                      <div className="marginL-20">
                        <Field name="amount" className="form-control" />
                        <ErrorMessage
                          name="amount"
                          component="div"
                          className="text-danger danger absolute"
                        />
                      </div>
                    </div>
                    <div className="wrapper">
                      <h4
                        className="text-nowrap"
                        style={{ color: "black", fontSize: 14 }}
                      >
                        Referral ID
                      </h4>
                      <div className="marginA-40">
                        <Field
                          name="ref_id"
                          className="form-control"
                          placeholder="(Optional)"
                        />
                        <ErrorMessage
                          name="ref_id"
                          component="div"
                          className="text-danger danger absolute"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <br />
                  <div className="row" style={{ justifyContent: "flex-end" }}>
                    <div className="button-container">
                      <button
                        id="Submit-btn-nero"
                        className="margin-b-10"
                        style={{
                          padding: "5px 15px",
                          border: "1px solid black",
                          borderRadius: "16px",
                          marginTop: 10,
                        }}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      
    </div>
  );
}

export default TopUpWallet;
